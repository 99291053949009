import React, { Component } from "react"
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps"

class MapComponent extends Component {
  render() {
    const location = "nature+health+48+walton+road+KT80DQ"
    const KEY = "AIzaSyDTZraCTb-STS-yZIYjt_WgIc8ObBFb68w"
    const url = `https://maps.googleapis.com/maps/api/js?key=${KEY}&v=3.exp&libraries=geometry,drawing,places`

    const GoogleMapContainer = withGoogleMap(props => (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: -34.397, lng: 150.644 }}
          >
            <Marker position={{ lat: -34.397, lng: 150.644 }} />
          </GoogleMap>
    ));

    return (
        <GoogleMapContainer 
            containerElement={<div style={{ height: `500px`, width: '500px' }} />} 
            mapElement={<div style={{ height: `100%` }} />}
            isMarkerShown
            googleMapURL={url}
            loadingElement={<div style={{ height: `100%` }} />}
        />
    )
  }
}

export default MapComponent;
